import Hero from './Hero';
import SejarahFilosofi from './SejarahFilosofi';
import PembuatanPhinisi from './PembuatanPhinisi';
import PopularArticle from './PopularArticle';
import JoinTrip from './JoinTrip';
import BookingPhinisi from './BookingPhinisi';

const Fragments = {
  Hero,
  SejarahFilosofi,
  PembuatanPhinisi,
  PopularArticle,
  JoinTrip,
  BookingPhinisi,
};

export default Fragments;
