import Logo from '../Assets/Logo.svg';
import LogoPNG from '../Assets/Logo.png';
import LogoWhite from '../Assets/LogoWhite.svg';
import Instagram from '../Assets/Instagram.svg';
import InstagramWhite from '../Assets/InstagramWhite.svg';
import Tiktok from '../Assets/Tiktok.svg';
import TiktokWhite from '../Assets/TiktokWhite.svg';
import Youtube from '../Assets/Youtube.svg';
import YoutubeWhite from '../Assets/YoutubeWhite.svg';
import Play from '../Assets/Play.svg';
import SejarahPhinisi from '../Assets/SejarahPhinisi.svg';
import FilosofiPhinisi from '../Assets/FilosofiPhinisi.svg';
import DanaIndonesiana from '../Assets/logo/danaindonesiana.jpg';
import LPDP from '../Assets/logo/lpdp.png';
import Kemdikbud from '../Assets/logo/kemdikbud.png';
import Hero1 from '../Assets/hero/1.png';
import Hero2 from '../Assets/hero/2.png';
import Hero3 from '../Assets/hero/3.png';
import Order1 from '../Assets/order/1.png';
import Order2 from '../Assets/order/2.png';
import Order3 from '../Assets/order/3.png';
import Order4 from '../Assets/order/4.png';
import Order5 from '../Assets/order/5.png';
import Order6 from '../Assets/order/6.svg';
import LambungPhinisi from '../Assets/LambungPhinisi.png';
import LabuanBajo from '../Assets/LabuanBajo.jpg';

const Images = {
  Logo,
  LogoPNG,
  LogoWhite,
  Instagram,
  InstagramWhite,
  Tiktok,
  TiktokWhite,
  Youtube,
  YoutubeWhite,
  Play,
  SejarahPhinisi,
  FilosofiPhinisi,
  DanaIndonesiana,
  LPDP,
  Kemdikbud,
  Hero1,
  Hero2,
  Hero3,
  Order1,
  Order2,
  Order3,
  Order4,
  Order5,
  Order6,
  LambungPhinisi,
  LabuanBajo,
};

export default Images;
