import React from 'react';
import {
  Box,
} from '@chakra-ui/react';
import Fragments from '../../Fragments';

export default function Component() {
  return (
    <Box py="6">
      <Fragments.JoinTrip />
      <Fragments.BookingPhinisi />
    </Box>
  );
}
