const ROUTES = {
  home: () => '/',
  artikel: () => '/artikel',
  sejarah: () => '/sejarah',
  filosofi: () => '/filosofi',
  pemesanan: () => '/pemesanan',
  penyewaan: () => '/penyewaan',
  terms: () => '/terms-and-conditions',
  privacy: () => '/privacy-policy',
  about: () => '/about',
  faq: () => '/faq',
  chat: () => '/chat',
  admin: () => '/admin',
};

export default ROUTES;
