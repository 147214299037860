import React from 'react';
import Fragments from '../../Fragments';

export default function Component() {
  return (
    <>
      <Fragments.Hero />
      <Fragments.SejarahFilosofi />
      <Fragments.PopularArticle />
      <Fragments.PembuatanPhinisi />
    </>
  );
}
