import Header from './Header';
import Footer from './Footer';
import SideNavPopular from './SideNav-Popular';
import SideNavRelated from './SideNav-Related';
import Login from './Login';
import Table from './Table';
import Confirmation from './Confirmation';
import ChatModal from './ChatModal';
import FixOrder from './FixOrder';
import ImagesModal from './ImagesModal';
import ContentModal from './ContentModal';
import Loading from './Loading';

const Elements = {
  Header,
  Footer,
  SideNavPopular,
  SideNavRelated,
  Login,
  Table,
  Confirmation,
  ChatModal,
  FixOrder,
  ImagesModal,
  ContentModal,
  Loading,
};

export default Elements;
