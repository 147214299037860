import Home from './Home';
import CreateArticle from './CreateArticle';
import DetailArticle from './DetailArticle';
import Article from './Article';
import Order from './Order';
import DetailVendor from './DetailVendor';
import DetailOrder from './DetailOrder';
import DetailTrip from './DetailTrip';
import DetailShip from './DetailShip';
import Rental from './Rental';
import Histories from './Histories';
import Filosofi from './Filosofi';
import Chat from './Chat';
import ChatAdminArea from './ChatAdminArea';
import FAQ from './FAQ';
import PrivacyPolicy from './PrivacyPolicy';
import AboutUs from './AboutUs';
import Terms from './Terms&Condition';
import Admin from './Admin';
import Auth from './Auth';

export default {
  Home,
  CreateArticle,
  DetailArticle,
  Article,
  Order,
  DetailVendor,
  DetailOrder,
  DetailTrip,
  DetailShip,
  Rental,
  Histories,
  Filosofi,
  Chat,
  ChatAdminArea,
  FAQ,
  PrivacyPolicy,
  AboutUs,
  Terms,
  Admin,
  Auth,
};
