export const admins = ['adm.phinisicenter@gmail.com', 'andi.irsandi765@gmail.com'];
export const progressLabel = [
  'Tahap Meramu Kayu',
  'Pemasangan Kalabiseang',
  'Pemasangan Papan Terasa',
  'Pemasangan Buku',
  'Pemasangan Papan Lamma',
  'Pemasangan Lepe Kalang',
  'Pemasangan Katabang',
  'Pemasangan Anjong Kapal',
  'Tahap Finishing Kapal',
];
